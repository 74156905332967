export default {
  truncate(string: string, maxLen: number): string {
    if (string.length > maxLen) {
      return `${string.slice(0, maxLen)}...`;
    }
    return string;
  },
  mongoObjectId(): string {
    const timestamp = (new Date().getTime() / 1000 | 0).toString(16);
    return timestamp + 'xxxxxxxxxxxxxxxx'.replace(/[x]/g, () => (Math.random() * 16 | 0).toString(16)).toLowerCase();
  },
};
