import Link from 'next/link';
import React, { ReactElement } from 'react';

import StringUtils from '@/lib/string';
import { getPath } from '@/lib/hub-path';
import classes from './styles.module.scss';

interface Props {
  project: ProjectResume
  resumeMaxLen?: number
}

function ProjectItem({ project, resumeMaxLen }: Props): ReactElement {
  return (
    <Link href={getPath(`/projects/${encodeURIComponent(project.url)}`)} passHref>
      <li className="mb-6 hover:bg-gray-100 cursor-pointer">
        <div className="flex justify-between">
          <span className={classes.title}>{project.title || ''}</span>
          {!Number.isNaN(parseInt(project.year, 10)) && <span className={classes.year}>{project.year || ''}</span>}
        </div>
        {project && project.resume && project.resume.length > 0 && (
          <p
            className={`${classes.resume} mt-2`}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: StringUtils.truncate(project.resume, resumeMaxLen || 200),
            }}
          />
        )}
      </li>
    </Link>
  );
}

ProjectItem.defaultProps = {
  resumeMaxLen: 200,
};

export default ProjectItem;
