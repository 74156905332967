import { gql } from 'graphql-request';

export const PUBLICATION = gql`
  query Publication($id: String!) {
    publication(id: $id) {
      _id
      html
      url
      university {
        _id
        name
        initials
      }
      user {
        _id
        firstname
        lastname
        avatar
        publicProfile
      }
      project {
        _id
        title
        document {
          title
          documentNodes {
            title
            slug
            nodeChilds {
              title
              slug
              content {
                innerText
                innerHtml
              }
            }
            content {
              innerText
              innerHtml
            }
          }
          year
        }
      }
    }
  }
`;

export const PUBLICATION_HTML = gql`
  query PublicationHtml($id: String!) {
    publicationHtml(id: $id)
  }
`;

export const PUBLICATIONS = gql`
  query Publications($options: FetchPublicationsOptions) {
    publications(options: $options) {
      _id
      url
      lastChange
    }
  }
`;

export const PUBLICATIONS_ONLY_ID = gql`
  query PublicationsOnlyId($options: FetchPublicationsOptions) {
    publications(options: $options) {
      _id
    }
  }
`;

export const PUBLICATIONS_SEARCH = gql`
  query SearchPublications($options: SearchPublicationsOptions!) {
    searchPublications(options: $options) {
      total
      projects {
        _id
        title
        keywords
        summaryRawText
        authors
        conclusionText
        contentRawText
        local
        type
        year
        url
        course
        type
      }
    }
  }
`;

export default {};
