import { HTML_TAGS } from './regex-common';

export function getProjectResume(project: ProjectSnapshot): string {
  const { document } = project;

  if (!document || !document.documentNodes) return '';

  const { documentNodes } = document;
  let resume = '';
  const preTextuals = documentNodes.find(
    (node) => node.slug === 'elementos-pre-textuais',
  );

  if (preTextuals?.nodeChilds) {
    const resumeNode = preTextuals.nodeChilds.find(
      (node) => node.slug === 'resume',
    );
    if (resumeNode?.content) {
      resume = resumeNode.content.innerText;
    }
  }

  if (!resume.length) {
    const textuals = documentNodes.find(
      (node) => node.slug === 'elementos-textuais',
    );

    if (textuals?.nodeChilds) {
      const firstNode = textuals.nodeChilds[0];
      if (firstNode?.content?.innerHtml) {
        resume = firstNode.content.innerHtml
          .replace(firstNode.title, '')
          .replace(HTML_TAGS, '');
      }
    }
  }

  return resume;
}

export default {};
