import React, { ReactElement } from 'react';
import classes from './styles.module.scss';

interface Props {
  text?: string | null
}

function Loading({ text }: Props): ReactElement {
  return (
    <div className={`flex items-center justify-center ${classes.loader}`}>
      <i className={`fas fa-circle-notch ${classes.icon}`} />
      {text && <div className="ml-2">{text}</div>}
    </div>
  );
}

Loading.defaultProps = {
  text: null,
};

export default Loading;
