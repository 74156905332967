import ProjectsList from '@/components/project/ProjectsList';
import {
  Fragment, ReactElement, useEffect, useState,
} from 'react';
import ls from 'localstorage-slim';

import { fetchMostViewedPublications } from '@/services/publication';
import Loading from '@/components/Loading';
import useTranslation from '@/hooks/useTranslation';
import classes from './styles.module.scss';

const LS_KEY = 'most-viewed-publications';

function MostViewedPublications(): ReactElement {
  const [publications, setPublications] = useState<ProjectResume[]>([]);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const fetchPublications = async () => {
    const stored: ProjectResume[] | null = ls.get(LS_KEY);

    if (stored) return setPublications(stored);

    setLoading(true);

    const data = await fetchMostViewedPublications() || [];

    setPublications(data);
    setLoading(false);

    if (data.length) ls.set(LS_KEY, data, { ttl: 86400 });
  };

  useEffect(() => {
    fetchPublications();
  }, []);

  return (
    <Fragment>
      <h2 className={`${classes.title}`}>{t('projects.mostRead')}</h2>
      {loading ? <Loading text={t('publications.loading')} /> : <ProjectsList projects={publications} />}
    </Fragment>
  );
}

export default MostViewedPublications;
