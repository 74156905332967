import { getProjectResume } from '@/lib/project';
import {
  PUBLICATION, PUBLICATIONS, PUBLICATIONS_ONLY_ID, PUBLICATIONS_SEARCH, PUBLICATION_HTML,
} from '../graphql/publication/queries';
import { fetcher } from './fetcher';

interface PublicationsWithViews extends Publication {
  views: number
}

export const fetchMostViewedPublications = async (): Promise<ProjectResume[]> => {
  const response = await fetch(process.env.NEXT_PUBLIC_MOST_VIEWED_PUBLICATIONS_URL || '');
  const publications = await response.json();

  publications.sort((a: PublicationsWithViews, b: PublicationsWithViews) => {
    if (a.views > b.views) {
      return -1;
    }

    if (a.views < b.views) {
      return 1;
    }

    return 0;
  });

  const projects = publications.map(({ project, url }: Publication) => {
    const { _id, document } = project;

    return {
      _id,
      title: document.title,
      resume: getProjectResume(project),
      year: document.year,
      url,
    };
  });

  return projects;
};

export default {
  async fetchPublication(id: string): Promise<Publication> {
    const { publication } = await fetcher({
      operation: PUBLICATION,
      variables: { id },
    });

    return publication;
  },
  async fetchPublicationHtml(id: string): Promise<string> {
    const { publicationHtml } = await fetcher({
      operation: PUBLICATION_HTML,
      variables: { id },
    });

    return publicationHtml;
  },
  async fetchPublications(options?: FetchPublicationsOptions): Promise<PublicProjectResume[]> {
    const { publications } = await fetcher({
      operation: options?.onlyId ? PUBLICATIONS_ONLY_ID : PUBLICATIONS,
      variables: { options },
    });

    return publications;
  },
  async searchPublications(options: SearchPublicationsParams): Promise<PublicationsSearchResult> {
    const { searchPublications } = await fetcher({
      operation: PUBLICATIONS_SEARCH,
      variables: { options },
    });

    return searchPublications;
  },
};
