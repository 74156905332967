import React, { ReactElement } from 'react';

import ProjectItem from '../ProjectItem';

interface Props {
  projects: ProjectResume[]
  resumeMaxLen?: number
}

function ProjectsList({ projects, resumeMaxLen }: Props): ReactElement {
  return (
    <ul data-testid="projects-list">
      {projects.map((project) => (
        <ProjectItem project={project} key={project._id} resumeMaxLen={resumeMaxLen} />))}
    </ul>
  );
}

ProjectsList.defaultProps = {
  resumeMaxLen: 200,
};

export default ProjectsList;
