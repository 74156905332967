import useSWR, { SWRResponse } from 'swr';
import { request, GraphQLClient } from 'graphql-request';

interface FetcherProps {
  operation: string
  variables?: any
  token?: string
}

export async function fetcher({
  operation, variables, token,
}: FetcherProps): Promise<any> {
  const apiUrl = process.env.NEXT_PUBLIC_API_URL || '';

  if (!token) return request(apiUrl, operation, variables);

  let headers;

  if (token) {
    const userTokenHeader = process.env.NEXT_PUBLIC_USER_TOKEN_HEADER || '';

    headers = {
      [userTokenHeader]: process.env.NEXT_PUBLIC_API_APP_KEY || '',
    };
  }

  const graphQLClient = new GraphQLClient(apiUrl, {
    headers,
  });

  return graphQLClient.request(operation, variables);
}

export default function useQuery(query: string): SWRResponse<Response, any> {
  return useSWR(query, fetcher);
}
